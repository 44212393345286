import { AxiosError } from "axios";
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";

import { SearchPaginationQuery } from "./api";
import {
    addComment,
    AddCommentPayload,
    assignDriver,
    AssignDriverPayload,
    assignTrailer,
    AssignTrailerError,
    AssignTrailerPayload,
    assignYardDriver,
    AssignYardDriverPayload,
    cancelTourOrder,
    changeTruck,
    ChangeTruckPayload,
    create,
    deleteTourOrder,
    details,
    downloadTourOrderBody,
    LoadingForcedPlatformPermissionPayload,
    forcedTrailerPermission,
    ForcedTrailerPermissionPayload,
    list,
    CreateTourOrderPayload,
    TourOrderIdPayload,
    TourOrdersListPayload,
    TourOrdersListResponse,
    updateDateOfTourOrder,
    trace,
    TracePayload,
    sendUpdateToDriver,
    assignTrailerDepotLocation,
    AssignTrailerDepotLocationPayload,
    CreateHazardTaskPayload,
    createHazardTask,
    DeleteHazardTaskPayload,
    deleteHazardTask,
    TrailerDepotForcedPlatformPermissionPayload,
    trailerDepotforcedPlatformPermission,
    loadingForcedPlatformPermission,
    TrailerDepotForcedDepotZonePermissionPayload,
    ValidateUnloadedSupportsPayload,
    validateUnloadedSupports,
    ValidateUnloadedSupportsError,
    UpdateHazardTaskPayload,
    updateHazardTask,
    sendDegradedMode,
    SendDegradedModePayload,
    AssignTrailerDepotLocationError,
    UpdateTrailerLocationPayload,
    updateTrailerLocation,
} from "./api/tourOrders";
import { TourOrder, Trace } from "./api/types";
import { carriersKeys } from "./carriers";
import { loadingsKeys } from "./loadings";
import { trailersKeys } from "./trailers";

export const tourOrdersKeys = {
    all: ["tourOrders"],
    lists: () => [...tourOrdersKeys.all, "list"],
    list: (params?: SearchPaginationQuery) => [...tourOrdersKeys.lists(), params],
    details: () => [...tourOrdersKeys.all, "details"],
    detail: (id?: TourOrderIdPayload) => [...tourOrdersKeys.details(), id],
    traces: () => [...tourOrdersKeys.all, "traces"],
    trace: ({ tourOrderId, originalPoint }: TracePayload) => [...tourOrdersKeys.traces(), tourOrderId, originalPoint],
    export: () => [...tourOrdersKeys.all, "export"],
    exportTourOrderBody: (tourOrderId: TourOrderIdPayload) => [...tourOrdersKeys.export(), "operators", tourOrderId],
};

export const useTourOrdersList = <TData = TourOrdersListResponse>(
    params?: TourOrdersListPayload,
    options?: UseQueryOptions<TourOrdersListResponse, AxiosError, TData>,
) => {
    return useQuery<TourOrdersListResponse, AxiosError, TData>(
        tourOrdersKeys.list(params),
        async () => await list(params),
        options,
    );
};

export const useTourOrderDetails = <TData = TourOrder>(
    id: TourOrderIdPayload,
    options?: UseQueryOptions<TourOrder, AxiosError, TData>,
) => {
    return useQuery<TourOrder, AxiosError, TData>(tourOrdersKeys.detail(id), async () => await details(id), options);
};

export const useAssignTrailer = (options?: UseMutationOptions<TourOrder, AssignTrailerError, AssignTrailerPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AssignTrailerError, AssignTrailerPayload>(
        async (params) => await assignTrailer(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.lists());

                // invalidate trailers list queries so they refetch wen reopening the modal of doing several switch between trailers
                queryClient.invalidateQueries(trailersKeys.lists());
            },
        },
    );
};

export const useUpdateTrailerLocation = (
    options?: UseMutationOptions<TourOrder, AxiosError, UpdateTrailerLocationPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, UpdateTrailerLocationPayload>(
        async (params) => await updateTrailerLocation(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};
export const useChangeTruck = (options?: UseMutationOptions<TourOrder, AxiosError, ChangeTruckPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, ChangeTruckPayload>(async (params) => await changeTruck(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.lists());
        },
    });
};

export const useAddOperationComment = (options?: UseMutationOptions<TourOrder, AxiosError, AddCommentPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, AddCommentPayload>(async (params) => await addComment(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.lists());
        },
    });
};

export const useCancelTourOrder = (options?: UseMutationOptions<TourOrder, AxiosError, TourOrderIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, TourOrderIdPayload>(async (params) => await cancelTourOrder(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.detail(variables));

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.lists());
        },
    });
};

export const useAssignYardDriver = (options?: UseMutationOptions<TourOrder, AxiosError, AssignYardDriverPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, AssignYardDriverPayload>(
        async (params) => await assignYardDriver(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.lists());
            },
        },
    );
};

export const useAssignDriver = (options?: UseMutationOptions<TourOrder, AxiosError, AssignDriverPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, AssignDriverPayload>(async (params) => await assignDriver(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));

            // invalidate carriers list queries so they refetch wen reopening the modal of doing several switch between drivers
            queryClient.invalidateQueries(carriersKeys.lists());

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.lists());
        },
    });
};

export const useCreateTourOrder = (options?: UseMutationOptions<TourOrder, AxiosError, CreateTourOrderPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, CreateTourOrderPayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.lists());
        },
    });
};

export const useForcedTrailerPermission = (
    options?: UseMutationOptions<TourOrder, AxiosError, ForcedTrailerPermissionPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, ForcedTrailerPermissionPayload>(
        async (params) => await forcedTrailerPermission(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

export const useLoadingForcedPlatformPermission = (
    options?: UseMutationOptions<TourOrder, AxiosError, LoadingForcedPlatformPermissionPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, LoadingForcedPlatformPermissionPayload>(
        async (params) => await loadingForcedPlatformPermission(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

export const useTrailerDepotForcedPlatformPermission = (
    options?: UseMutationOptions<TourOrder, AxiosError, TrailerDepotForcedPlatformPermissionPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, TrailerDepotForcedPlatformPermissionPayload>(
        async (params) => await trailerDepotforcedPlatformPermission(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

export const useTrailerDepotforcedDepotZonePermission = (
    options?: UseMutationOptions<TourOrder, AxiosError, TrailerDepotForcedDepotZonePermissionPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, TrailerDepotForcedDepotZonePermissionPayload>(
        async (params) => await trailerDepotforcedPlatformPermission(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

export const useTrace = <TData = Trace>(payload: TracePayload, options?: UseQueryOptions<Trace, AxiosError, TData>) => {
    return useQuery<Trace, AxiosError, TData>(tourOrdersKeys.trace(payload), async () => await trace(payload), options);
};

export const useAssignTrailerDepotLocation = (
    options?: UseMutationOptions<TourOrder, AssignTrailerDepotLocationError, AssignTrailerDepotLocationPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AssignTrailerDepotLocationError, AssignTrailerDepotLocationPayload>(
        async (params) => await assignTrailerDepotLocation(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

// Super Admin
export const useUpdateDateOfTourOrder = (options?: UseMutationOptions<TourOrder, AxiosError, TourOrderIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, TourOrderIdPayload>(
        async (params) => await updateDateOfTourOrder(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables));

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.lists());
            },
        },
    );
};

export const useDownloadTourOrderBody = <TData = undefined>(
    params: TourOrderIdPayload,
    options?: UseQueryOptions<null, AxiosError, TData>,
) => {
    return useQuery<null, AxiosError, TData>(
        tourOrdersKeys.export(),
        async () => await downloadTourOrderBody(params),
        options,
    );
};

export const useDeleteTourOrder = (options?: UseMutationOptions<TourOrder, AxiosError, TourOrderIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, TourOrderIdPayload>(async (params) => await deleteTourOrder(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(tourOrdersKeys.lists());

            queryClient.invalidateQueries(loadingsKeys.lists());
            queryClient.invalidateQueries(trailersKeys.lists());
        },
    });
};

export const useSendUpdateToDriver = (options?: UseMutationOptions<TourOrder, AxiosError, TourOrderIdPayload>) => {
    return useMutation<TourOrder, AxiosError, TourOrderIdPayload>(async (params) => await sendUpdateToDriver(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);
        },
    });
};

export const useValidateUnloadedSupports = (
    options?: UseMutationOptions<TourOrder, AxiosError, ValidateUnloadedSupportsPayload>,
) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, ValidateUnloadedSupportsError, ValidateUnloadedSupportsPayload>(
        async (params) => await validateUnloadedSupports(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                queryClient.invalidateQueries(tourOrdersKeys.lists());
            },
        },
    );
};

export const useSendDegradedMode = (options?: UseMutationOptions<TourOrder, AxiosError, SendDegradedModePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<TourOrder, AxiosError, SendDegradedModePayload>(
        async (params) => await sendDegradedMode(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                queryClient.invalidateQueries(tourOrdersKeys.lists());
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

export const useCreateHazardTask = (options?: UseMutationOptions<undefined, AxiosError, CreateHazardTaskPayload>) => {
    const queryClient = useQueryClient();
    return useMutation<undefined, AxiosError, CreateHazardTaskPayload>(
        async (params) => await createHazardTask(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                queryClient.invalidateQueries(tourOrdersKeys.lists());
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};

export const useDeleteHazardTask = (options?: UseMutationOptions<undefined, AxiosError, DeleteHazardTaskPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, DeleteHazardTaskPayload>(
        async (params) => await deleteHazardTask(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.lists());
            },
        },
    );
};

export const useUpdateHazardTask = (options?: UseMutationOptions<undefined, AxiosError, UpdateHazardTaskPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, UpdateHazardTaskPayload>(
        async (params) => await updateHazardTask(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(tourOrdersKeys.detail(variables.tourOrderId));
            },
        },
    );
};
