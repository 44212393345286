import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import jwt_decode from "jwt-decode";

import { TourOrder, TourOrderStatus, User, ValueListSlug } from "../queries/api/types";
import { ColorTagColor } from "../components/ColorTag";
import constants from "../config/constants";

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = !import.meta.env.PROD;
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: dodgerblue; font-weight: bold", messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: cornflowerblue; font-weight: bold", messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: darkorange; font-weight: bold", messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log("%c[Logger]", "color: tomato; font-weight: bold", messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (user?: Pick<User, "firstName" | "lastName">) => {
    if (!user?.firstName && !user?.lastName) {
        return "";
    }

    return `${user?.firstName ?? ""}${user?.lastName ? ` ${user?.lastName}` : ""}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== "string") {
        return "";
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== "string") {
        return "";
    }

    return str.split(" ").map(capitalize).join(" ");
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(" ");
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
        return true;
    }
};

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).reduce<Record<string, any>>((acc, param) => {
        if (Object.prototype.hasOwnProperty.call(acc, param[0])) {
            if (Array.isArray(acc[param[0]])) {
                return { ...acc, [param[0]]: [...acc[param[0]], param[1]] };
            } else {
                return { ...acc, [param[0]]: [acc[param[0]], param[1]] };
            }
        }

        return { ...acc, [param[0]]: param[1] };
    }, {});

export const requiredRule = { required: true, message: "champ requis" };
export const isEmailRule = {
    pattern: /^[a-zA-Z0-9](?:[a-zA-Z0-9._-]*[a-zA-Z0-9])*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
    message: "email non valide",
    validateTrigger: "onSubmit",
};

export const sortOrderConverter = (value: string) => {
    switch (value) {
        case "ascend":
            return "asc";
        case "descend":
            return "desc";
        default:
            return value;
    }
};

export const addDefaultColumnSorting = <T>(
    sort: string | undefined,
    sortOrder: string | undefined,
    columns: ColumnsType<T>,
) => {
    return columns.map((column) => {
        if (sort && sortOrder && column.key === sort) {
            return {
                ...column,
                defaultSortOrder: sortOrder === "asc" ? ("ascend" as SortOrder) : ("descend" as SortOrder),
            };
        }
        return column;
    });
};

/**
 * Remove certain props from an object
 * @param obj Object
 * @param keys Object keys
 * @returns Object without certain props
 */
export function omit<T, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
    const data = { ...obj };
    for (const key of keys) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete data[key];
    }
    return data;
}

export const parseStringToBoolean = (value?: string) => {
    if (value === undefined) {
        return undefined;
    }

    return value === "true";
};

export const formatValue = <T extends string | number | null | JSX.Element>(value?: T) => value ?? "-";

export const phoneNumberFormat = new RegExp(/^0\d{9}$/);

export const downloadFile = (file: string, filename?: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.download = filename ?? "fichier";
    downloadLink.href = file;
    downloadLink.click();

    downloadLink.remove();
};

export const formatPhoneNumber = (value?: string): string => {
    if (!value) {
        return "";
    }
    return value?.replace(/\D/g, "").replace(/\d{2}(?=.)/g, "$& ");
};

export const getValueListSuccessMessage = (slug: ValueListSlug, messageType: "create" | "edit" | "delete") => {
    const valueListSlugMessageNames = {
        [ValueListSlug.delays]: "",
        [ValueListSlug.activities]: "",
        [ValueListSlug.products]: "",
        [ValueListSlug.palletSupports]: "",
        [ValueListSlug.reasonsRefusingTruck]: "Motif",
        [ValueListSlug.packagingTypes]: "Type",
        [ValueListSlug.trailerDropZones]: "Zone",
        [ValueListSlug.trailerTypes]: "Type",
        [ValueListSlug.driverHazards]: "Aléa",
        [ValueListSlug.reasonsForNonCompliance]: "Motif",
        [ValueListSlug.warehouses]: "Entrepôt",
        [ValueListSlug.platforms]: "Quai",
        [ValueListSlug.operatingHazards]: "",
    };

    let verb = "";
    switch (messageType) {
        case "create":
            verb = "créé";
            break;
        case "edit":
            verb = "modifié";
            break;
        case "delete":
            verb = "supprimé";
            break;
    }

    const agreement = {
        [ValueListSlug.delays]: "m",
        [ValueListSlug.products]: "m",
        [ValueListSlug.palletSupports]: "f",
        [ValueListSlug.activities]: "f",
        [ValueListSlug.reasonsRefusingTruck]: "m",
        [ValueListSlug.packagingTypes]: "m",
        [ValueListSlug.trailerDropZones]: "f",
        [ValueListSlug.trailerTypes]: "m",
        [ValueListSlug.driverHazards]: "m",
        [ValueListSlug.reasonsForNonCompliance]: "m",
        [ValueListSlug.warehouses]: "m",
        [ValueListSlug.platforms]: "m",
        [ValueListSlug.operatingHazards]: "m",
    };

    return `${valueListSlugMessageNames[slug] || "Valeur"} ${verb}${agreement[slug] === "m" ? "" : "e"} avec succès`;
};

export const getTourOrderStatusTagColor = (tourOrder: TourOrder, delayLevel?: number): ColorTagColor => {
    const { status } = tourOrder;
    const delayLevelsColors = [ColorTagColor.orange, ColorTagColor.red];
    const hasDelay = delayLevel !== undefined && delayLevel > -1;

    if (
        hasDelay &&
        [
            TourOrderStatus.readyToStart,
            TourOrderStatus.loading,
            TourOrderStatus.loaded,
            TourOrderStatus.onDelivery,
            TourOrderStatus.onReturn,
        ].includes(status)
    ) {
        return delayLevelsColors[delayLevel];
    }

    switch (status) {
        case TourOrderStatus.readyToStart:
            return ColorTagColor.darkBlue;

        case TourOrderStatus.loading:
            return ColorTagColor.purple;

        case TourOrderStatus.virtuallyLoaded:
        case TourOrderStatus.loaded:
        case TourOrderStatus.onDelivery:
        case TourOrderStatus.onReturn:
        case TourOrderStatus.onReturnWithSupports:
        case TourOrderStatus.supportsUnloaded:
        case TourOrderStatus.overhanging:
            return ColorTagColor.blue;

        case TourOrderStatus.unloadingSupports:
            return ColorTagColor.orange;

        case TourOrderStatus.completed:
        case TourOrderStatus.completedWithSupportsUnloaded:
            if (hasDelay) {
                return ColorTagColor.greenRedDashed;
            }
            return ColorTagColor.green;

        case TourOrderStatus.canceled:
            return ColorTagColor.grey;

        case TourOrderStatus.postponed:
            return ColorTagColor.white;

        default:
            return ColorTagColor.white;
    }
};

export const isEmail = (value: string) => {
    return value.match(constants.EMAIL_REGEX);
};

export const formatDuration = (value: number | undefined, options?: { short?: boolean }) => {
    if (value === undefined || value < 0) {
        return "-";
    }

    const { short } = options ?? {};
    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    return `${hours > 0 ? `${hours} h ` : ""}${minutes} ${short ? "" : "min"}`.trim();
};

export const formatMetersToKilometers = (value?: number) => {
    if (value === undefined) {
        return "0 km";
    }

    return `${Math.round((value / 1000) * 10) / 10} km`;
};
