import { useEffect, useRef, useState, VFC } from "react";
import { Dropdown, Badge, Button, Spin } from "antd";
import { BellOutlined, CheckOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";

import {
    notificationsKeys,
    useNotificationList,
    useNotificationListInfinite,
    useReadAllNotifications,
} from "../queries/notifications";
import constants from "../config/constants";
import { SortOrder } from "../queries/api/types";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import ApiResult from "./ApiResult";
import BasicList from "./BasicList";
import { classNames } from "../helpers";
import NotificationCard from "./NotificationCard";

let animationTimeout: number;

const NotificationsDropdown: VFC = () => {
    const queryClient = useQueryClient();
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [refetchInterval, setRefetchInterval] = useState<number>(constants.NOTIFICATIONS_REFETCH_INTERVAL_IN_MS);
    const loadMoreRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const isAtBottom = useIntersectionObserver(loadMoreRef, { threshold: 0.1 });

    const { data: notificationCountList } = useNotificationList(
        {
            pageSize: 1,
        },
        {
            refetchInterval,
            onError: () => setRefetchInterval(0),
        },
    );
    const {
        data: notificationList,
        isError,
        isSuccess,
        error,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useNotificationListInfinite(
        {
            pageSize: 5,
            sort: "sendAt",
            sortOrder: SortOrder.desc,
        },
        {
            enabled: isMenuOpen,
            getNextPageParam: (lastPage) => (lastPage.page === lastPage.pageCount - 1 ? undefined : lastPage.page + 1),
            refetchInterval: refetchInterval ? refetchInterval + 5 : 0,
            onError: () => setRefetchInterval(0),
        },
    );
    const { mutate: readAllNotifications } = useReadAllNotifications();

    const notifications = notificationList?.pages?.map((page) => page.items).flat();

    const onClickMarkAllAsRead = () => {
        setIsMarkingAllAsRead(true);
        readAllNotifications(undefined, {
            onSuccess: () => setIsMenuOpen(false),
            onSettled: () => {
                setIsMarkingAllAsRead(false);
                setIsMenuOpen(false);
            },
        });
    };

    const menu = (
        <div id="notifications-menu" className="max-h-[460px] overflow-y-auto overflow-x-hidden" ref={dropdownRef}>
            {isLoading && (
                <div key="loading" className="p-16 text-center">
                    <Spin />
                </div>
            )}
            {!isLoading && isError && (
                <div key="error" className="p-16">
                    <ApiResult status={error?.response?.status} inline />
                </div>
            )}
            {!isLoading && isSuccess && (
                <>
                    {!notifications?.length ? (
                        <div key="disabled" className="p-16 text-sm text-brand-neutral-grey">
                            Vous n avez pas de notifications
                        </div>
                    ) : (
                        <BasicList>
                            {notifications?.map((notification) => (
                                <li key={notification.id}>
                                    <NotificationCard
                                        notification={notification}
                                        closeNotifications={() => setIsMenuOpen(false)}
                                    />
                                </li>
                            ))}
                            <li
                                key="load-more"
                                className={classNames(
                                    "text-center",
                                    (isFetchingNextPage || (hasNextPage && !isFetchingNextPage)) && "p-16",
                                )}
                            >
                                {(isFetchingNextPage || (hasNextPage && !isFetchingNextPage)) && <Spin />}
                            </li>
                            <li className="mark-all-as-read-li" key="mark-all-as-read">
                                <Button
                                    onClick={onClickMarkAllAsRead}
                                    className="mark-all-as-read items-center justify-center font-medium text-brand-blue-600"
                                >
                                    {isMarkingAllAsRead ? (
                                        <Spin className="text-base" />
                                    ) : (
                                        <CheckOutlined className="text-base text-brand-blue-600" />
                                    )}
                                    <span className="ml-8 font-bold text-brand-blue-600">Tout marquer comme lu</span>
                                </Button>
                            </li>
                        </BasicList>
                    )}
                </>
            )}
            <div
                ref={loadMoreRef}
                style={{
                    height: 1,
                    lineHeight: 0,
                    marginTop: !isLoading && isSuccess && notifications?.length ? -9 : 0,
                }}
            >
                &nbsp;
            </div>
        </div>
    );

    useEffect(() => {
        if (isAtBottom && hasNextPage) {
            fetchNextPage();
        }
    }, [isAtBottom, hasNextPage, fetchNextPage]);

    useEffect(
        () => () => {
            window.clearTimeout(animationTimeout);
        },
        [],
    );

    return (
        <span className="flex">
            <Dropdown
                placement="topRight"
                trigger={["click"]}
                overlay={menu}
                onOpenChange={(open) => {
                    if (open) {
                        queryClient.invalidateQueries(notificationsKeys.all);
                    }
                    setIsMenuOpen(open);
                    animationTimeout = window.setTimeout(() => {
                        dropdownRef?.current?.scrollTo({
                            top: 0,
                        });
                    }, 210);
                }}
                open={isMenuOpen}
                overlayClassName="notification-dropdown"
                forceRender
            >
                <Button
                    type="text"
                    icon={
                        <Badge
                            count={notificationCountList?.receivedCount ?? 0}
                            offset={[0, 2]}
                            style={{ backgroundColor: "#f5222d", padding: "0 6px" }}
                        >
                            <BellOutlined className="text-lg text-brand-blue-600" />
                        </Badge>
                    }
                />
            </Dropdown>
        </span>
    );
};

export default NotificationsDropdown;
